import React from "react";
import { Helmet } from "react-helmet";

export const ArticleJsonLd = ({ article }) => {
  const articleJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://adamfard.com/#organization",
        name: "Adam Fard Studio",
        url: "https://adamfard.com/",
        sameAs: [
          "https://www.linkedin.com/company/adam-fard/",
          "https://twitter.com/adamfard_",
          "https://medium.com/@adam.fard/",
          "https://dribbble.com/adamfardstudio",
          "https://www.behance.net/AdamFardStudio/"
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://adamfard.com/#logo",
          inLanguage: "en-US",
          url:
            "https://d33wubrfki0l68.cloudfront.net/019d03c7322285c0179d2efa7dfd7f4d0f08503d/62388/static/c3ead8266a0ba7e8c3e2d7638a3b4941/14b42/ux-design-agency-3.jpg",
          width: 500,
          height: 500,
          caption: "Adam Fard Logo"
        },
        image: { "@id": "https://adamfard.com/#logo" },
        founder: {
          "@type": "Person",
          name: "Adam Fard",
          url: "https://twitter.com/adamfard_",
          sameAs: "https://twitter.com/adamfard_"
        },
        foundingDate: "2016-01-01",
        numberOfEmployees: 10,
        slogan: "Scale your startup through exceptional product experience",
        description:
          "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
        legalName: "Adam Fard UX Studio"
      },
      {
        "@type": "WebSite",
        "@id": "https://adamfard.com/#website",
        url: "https://adamfard.com/",
        name: "Adarm Fard",
        description:
          "Improve your app's usability, retention rate, and conversion rate while achieving your business goals with UX Design Studio.",
        publisher: { "@id": "https://adamfard.com/#organization" },
        potentialAction: [
          {
            "@type": "SearchAction",
            target: "https://adamfard.com/?s={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        ],
        inLanguage: "en-US",
        copyrightHolder: { "@id": "https://adamfard.com/#organization" }
      },
      {
        "@type": "ImageObject",
        "@id": `https://adamfard.com/blog/${article.slug}/#primaryimage`,
        inLanguage: "en-US",
        url: article.cardImage?.url || "",
        width: 1200,
        height: 628,
        caption: article.title
      },
      {
        "@type": "WebPage",
        "@id": `https://adamfard.com/blog/${article.slug}/#webpage`,
        url: `https://adamfard.com/blog/${article.slug}`,
        name: article.title,
        isPartOf: { "@id": "https://adamfard.com/#website" },
        primaryImageOfPage: {
          "@id": `https://adamfard.com/blog/${article.slug}/#primaryimage`
        },
        datePublished: article.date,
        dateModified: article.date,
        description: article.seo?.description || "",
        breadcrumb: {
          "@id": `https://adamfard.com/blog/${article.slug}/#breadcrumb`
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`https://adamfard.com/blog/${article.slug}`]
          }
        ]
      },
      {
        "@type": "BreadcrumbList",
        "@id": `https://adamfard.com/blog/${article.slug}/#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://adamfard.com/",
              url: "https://adamfard.com/",
              name: "Adarm Fard"
            }
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://adamfard.com/blog/",
              url: "https://adamfard.com/blog/",
              name: "Blog"
            }
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@type": "WebPage",
              "@id": `https://adamfard.com/blog/${article.slug}`,
              url: `https://adamfard.com/blog/${article.slug}`,
              name: article.title
            }
          }
        ]
      },
      {
        "@type": ["Article"],
        "@id": `https://adamfard.com/blog/${article.slug}/#article`,
        isPartOf: {
          "@id": `https://adamfard.com/blog/${article.slug}/#webpage`
        },
        author: { "@id": "https://twitter.com/adamfard_" },
        headline: article.title,
        datePublished: article.date,
        dateModified: article.date,
        mainEntityOfPage: {
          "@id": `https://adamfard.com/blog/${article.slug}/#webpage`
        },
        commentCount: 0,
        publisher: { "@id": "https://adamfard.com/#organization" },
        image: {
          "@id": `https://adamfard.com/blog/${article.slug}/#primaryimage`,
          url: article.cardImage?.url || ""
        },
        keywords: article.keywords?.split(",").map(s => s.trim()),
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "CommentAction",
            name: "Comment",
            target: [`https://adamfard.com/blog/${article.slug}/#respond`]
          }
        ],
        copyrightYear: new Date().getFullYear(),
        copyrightHolder: { "@id": "https://adamfard.com/#organization" }
      },
      {
        "@type": "Person",
        "@id": "https://twitter.com/adamfard_",
        name: "Adarm Fard",
        image: {
          "@type": "ImageObject",
          "@id": "https://adamfard.com/#personlogo",
          inLanguage: "en-US",
          url:
            "https://pbs.twimg.com/profile_images/1073914053410603010/vmGznrIf_400x400.jpg",
          caption: "Adarm Fard"
        },
        description:
          "We help Tech Companies Grow by improving their Product Experience",
        sameAs: [
          "https://www.linkedin.com/company/adam-fard/",
          "https://twitter.com/adamfard_",
          "https://medium.com/@adam.fard/",
          "https://dribbble.com/adamfardstudio",
          "https://www.behance.net/AdamFardStudio/"
        ]
      }
    ]
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": `https://adamfard.com/blog/${article.slug}/#Webpage`,
    url: `https://adamfard.com/blog/${article.slug}`,
    headline: article.title,
    primaryImageOfPage: [article.cardImage?.url || ""],
    dateModified: article.date,
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description: article.seo?.description || "",
    image: article.cardImage?.url || "",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "3",
    description: article.seo?.description || "",
    disambiguatingDescription: article.seo?.description || "",
    mainEntityOfPage: `https://adamfard.com/blog/${article.slug}`,
    image: article.cardImage?.url || "",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: { "@id": "https://adamfard.com/blog", name: "Blog" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": `https://adamfard.com/blog/${article.slug}`,
          name: article.title
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(articleJson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
