import React, { useState, useEffect } from "react";
import { PRODUCTION_SITE_URL, FIREBASE_URL } from "../constants/common";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton
} from "react-share";
import IconSocialTwitter from "../images/icons/social/twitter.svg";

import getRandomArbitrary from "../utils/getRandomArbitrary";

const SocialShareFloating = ({ id, link }) => {
  const ShareURL = `${PRODUCTION_SITE_URL}${link}`;
  const [shares, setShares] = useState("");

  //const mixpanel = useMixpanel();

  // random number for the inital views between 20 and 100 views
  const initShares = getRandomArbitrary(20, 100);

  useEffect(() => {
    fetch(`${FIREBASE_URL}/shares/${id}.json`)
      .then((r) => r.text())
      .then((r) => parseInt(r))
      .then((shares) => {
        if (!shares) {
          const newShares = initShares;
          fetch(`${FIREBASE_URL}/shares/${id}.json`, {
            method: "PUT",
            body: newShares.toString(),
          }).then();

          return;
        }

        const newShares = shares || initShares;
        setShares(newShares.toString());
      });
  }, [id]);

  const track = (event) => {
    //Track event on Mixpanel
    //mixpanel.track(event);

    // If shares have a non-null value and if any share button is clicked,
    // increment the existing value and PUT in Firebase.

    if (shares) {
      const newShares = parseInt(shares) + 1;
      fetch(`${FIREBASE_URL}/shares/${id}.json`, {
        method: "PUT",
        body: newShares.toString(),
      }).then();
    }
  };

  return (
    <div className="social-share-floating">
      <div className="total-shares-container">
        {/* <span className="total-shares">{shares || "..."}</span> */}
        <label className="total-shares-label">Share:</label>
      </div>
      <LinkedinShareButton
        beforeOnClick={() => track("LinkdIn share")}
        url={ShareURL}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </LinkedinShareButton>

      <TwitterShareButton
        beforeOnClick={() => track("Twitter share")}
        url={ShareURL}
      >
        <IconSocialTwitter className="" />
      </TwitterShareButton>

      <FacebookShareButton
        beforeOnClick={() => track("Facebook share")}
        url={ShareURL}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      </FacebookShareButton>

      <RedditShareButton
        beforeOnClick={() => track("Reddit share")}
        url={ShareURL}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z" />
        </svg>
      </RedditShareButton>
    </div>
  );
};

export default SocialShareFloating;
