import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import CardBlog from '../components/CardBlog';
import { FaqJson } from '../components/Faq';
import { ArticleJsonLd } from '../components/jsonld/ArticleJsonLd';
import Layout from '../components/layout';
import NewsletterFormHorizontal from '../components/NewsletterFormHorizontal';
import SocialShare from '../components/SocialShare';
import SocialShareFloating from '../components/SocialShareFloating';
import StructuredTextRenderer, {
  StructuredModuleTypes,
} from '../components/StructuredTextRenderer';
import ViewCounter from '../components/ViewCounter';
import useScriptForm from '../hooks/useScript';
import DecorationBlob19 from '../images/decorations/deco-blob-19.svg';
import useInViewPort from '../hooks/useInViewPort';
import isElementsOverlap from '../utils/isElementsOverlap';
import CalendlyButton from '../components/CalendlyPopupButton';
import { replaceVariables, replaceVariablesInObject } from '../utils/replaceDate';

const ArticleTemplate = ({ data, location: { pathname } }) => {
  const [inViewRef] = useInViewPort();
  const ArticleData = data.datoCmsArticle;
  const ArticlesData = data.allDatoCmsArticle.edges;
  const buildDate = data.buildDate;

  const elementsOverlapHandler = (element, elementToHide) => {
    if (!element?.getBoundingClientRect()) return;

    const elementsOverlap = isElementsOverlap(
      element.getBoundingClientRect(),
      elementToHide.getBoundingClientRect()
    );
    if (elementsOverlap) {
      elementToHide.style.opacity = '0';
    } else {
      elementToHide.style.opacity = '1';
    }
  };

  useEffect(() => {
    elementsOverlapHandler(
      document.querySelectorAll('.article-cta')[0],
      document.querySelector('.social-share')
    );

    document.addEventListener('scroll', scrollHandler, true);
    return () => {
      document.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  const scrollHandler = () => {
    const cta = document.querySelectorAll('.article-cta');
    const floatElement = inViewRef.current;
    cta.forEach((item) => {
      elementsOverlapHandler(item, floatElement);
    });
  };

  const faqs = ArticleData.contentStructuredText.blocks
    .filter((b) => b.__typename === StructuredModuleTypes.DatoCmsFaqItem)
    .map(({ answer, question }) => ({
      answer,
      question,
    }));

  return (
    <>
      <FaqJson faqs={faqs} />
      <ArticleJsonLd article={ArticleData} />

      <Layout
        footerBgClass="bg-white"
        meta={{
          seo: {
            ...ArticleData.seo,
            title:
              !ArticleData.seo || !ArticleData.seo.title || ArticleData.seo.title === ''
                ? replaceVariables(buildDate, ArticleData.title)
                : replaceVariables(buildDate, ArticleData.seo.title),
            description:
              !ArticleData.seo || !ArticleData.seo.description || ArticleData.seo.description === ''
                ? replaceVariables(buildDate, ArticleData.cardDescription)
                : replaceVariables(buildDate, ArticleData.seo.description),
            image: ArticleData?.seo?.image
              ? ArticleData.seo.image
              : { url: ArticleData.cardImage.url },
          },
          articleSeo: {
            author: ArticleData.author[0]?.fullName || 'Adam Fard',
            category: ArticleData.category.name,
            canonicalLink: ArticleData.canonicalLink,
          },
          path: pathname,
        }}
      >
        <div className="article-template-page">
          <section className="pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb align-items-center">
                        <li className="breadcrumb-item">
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li className="breadcrumb-item align-items-center">
                          {ArticleData.category.name}
                        </li>
                      </ol>
                    </nav>
                    <span className="badge bg-primary-alt">
                      <ViewCounter id={ArticleData.slug} /> views
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="p-0">
            <div className="container">
              <div className="row justify-content-left position-relative">
                <div className="col-md-7" />
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12">
                  <h1 className="display-4">{replaceVariables(buildDate, ArticleData.title)}</h1>

                  <div className='d-flex flex-row'>
                    {Array.isArray(ArticleData.author) && (ArticleData.author).map((author, index) => (
                      <div key={index} className="d-block d-flex align-items-center py-1 mb-2 mr-4">
                        <GatsbyImage
                          imgClassName="avatar"
                          className="avatar mr-1"
                          image={author.avatar.gatsbyImageData}
                          alt={author.fullName}
                          loading="eager"
                        />

                        <div>
                          <div className="">
                            {author.fullName}
                            {ArticleData.author.length > 1 ? '' : ', ' + author.role}
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>

                  <div className="rounded mb-4 overflow-hidden">
                    <GatsbyImage
                      image={ArticleData.cardImage?.gatsbyImageData}
                      alt={ArticleData.title}
                      loading="eager"
                    />
                  </div>
                  <article
                    id="article-content"
                    className="article article-blog"
                  >
                    <StructuredTextRenderer
                      data={replaceVariablesInObject(buildDate, ArticleData.contentStructuredText)}
                    />
                  </article>
                </div>
                <div
                  className="d-none d-lg-block col-xl-3 col-lg-4 col-3 ml-auto"
                  style={{
                    maxHeight: '100%',
                    alignSelf: 'auto',
                  }}
                >
                  {/* <div className="card card-sm card-body card--no-hover bg-white mb-6">
                    <div className="my-auto text-center">
                      <GatsbyImage
                        className="avatar mr-2 mb-2"
                        image={ArticleData.author.avatar.gatsbyImageData}
                        alt={ArticleData.author.fullName}
                        loading="eager"
                      />
                      <p>
                        <strong>
                          {ArticleData.author.fullName}
                          {ArticleData.author.fullName == 'Adam Fard'
                            ? ' . Founder'
                            : ' . ' + ArticleData.author.role}
                        </strong>
                      </p>
                      <p>
                        Helping startups to improve their app’s usability &
                        realize their business goals
                      </p>
                      <Link to="/about-us" className="hover-arrow">
                        Learn more
                      </Link>
                    </div>
                  </div> */}

                  {/* <div className="card card-sm card-body card--no-hover bg-primary-3 text-light mb-6">
                    <div className="my-auto text-left">
                      <div id="activecampaign-form" className="form-small">
                        <div id="activecampaign-form__wrapper">
                          <h4 className="h4 text-center mb-2">
                            Exclusive Articles & Strategies
                          </h4>
                          <p className="text-center mb-2">
                            for Startups, UX Designers & Entrepreneurs
                          </p>
                          <div id="activecampaign-form">
                            <div id="activecampaign-form__wrapper">
                              <div className=" _form_7"></div>
                            </div>
                          </div>
                          {useScriptForm(
                            'https://adamfard48700.activehosted.com/f/embed.php?id=7'
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <SocialShare link={pathname} className="mb-4" />

                  <div
                    ref={inViewRef}
                    className="sticky-top card-sm card card-body card--no-hover bg-primary-alt"
                    style={{
                      top: 'calc(50vh - 175px)',
                      marginBottom: 200,
                      marginTop: 700,
                      zIndex: 10,
                    }}
                  >
                    <div className="text-center">
                      <h4 className="h4 mb-2">{ArticleData.ctaTitle}</h4>
                      <p className="">{ArticleData.ctaContent}</p>
                      
                      <CalendlyButton 
                        bntClass='btn btn-primary mt-2'
        
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pt-0">
              <div className="row justify-content-left">
                <div className="col-xl-8 col-lg-8 col-md-10">
                  <hr />
                  <SocialShare link={pathname} title={ArticleData.title} />
                </div>
              </div>
            </div>
          </section>

          <section className="related-stories o-hidden has-divider mt-4">
            <div className="decoration-wrapper">
              <div
                className="decoration deco-blog-bg"
                style={{ top: -370, overflowX: 'hidden', width: '100%' }}
              >
                <DecorationBlob19
                  className="bg-primary-alt flip-x"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="h1">Related Stories</h3>
                </div>
              </div>
              <div className="row">
                {ArticlesData.map(({ node }) => (
                  <div className="col-md-6 col-lg-4" key={node.id}>
                    <CardBlog cardData={node} />
                  </div>
                ))}
              </div>
            </div>
          </section>
          <NewsletterFormHorizontal />
        </div>
      </Layout>

      <SocialShareFloating id={ArticleData.slug} link={pathname} />
    </>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    datoCmsArticle(id: { eq: $id }) {
      id
      title
      cardDescription
      keywords
      category {
        name
      }
      date
      author {
        fullName
        role
        avatar {
          gatsbyImageData(width: 150, layout: CONSTRAINED)
        }
      }
      cardImage {
        url
        gatsbyImageData(width: 1600, layout: CONSTRAINED)
      }
      contentStructuredText {
        value
        blocks {
          __typename
          ... on DatoCmsFaqItem {
            id: originalId
            answer
            question
          }
          ... on DatoCmsImageBlock {
            id: originalId
            caption
            noVerticalMargin
            edgeToEdge
            image {
              customData
              url
              alt
              format
              mimeType
              url
              video {
                mp4Url
              }
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            badge
            buttonText
            title
            ctaContent
            buttonLink
            image {
              customData
              alt
              title
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          ... on DatoCmsScrolltocta {
            id: originalId
            content
          }
          ... on DatoCmsHtmlBlock {
            id: originalId
            htmlField
            htmlCaption
          }
        }
      }
      slug
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      canonicalLink
      ctaTitle
      ctaContent
      ctaLabel
    }

    buildDate: currentBuildDate

    allDatoCmsArticle(
      limit: 3
      filter: { id: { ne: $id } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 355, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
